
import { textsObject } from '@/texts/flowTexts';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  computed: {
    ...mapState('steps', ['pageSettings']),
  },
})
export default class TitleDescriptionUniversal extends Vue {
  public pageSettings!: any;

  textsObject = textsObject;

  get text(): any {
    const routeName = this.$route.name as string;
    const flowVersion = this.$store.getters['flowStages/getCurrentFlow'];

    return this.textsObject[routeName][flowVersion] || this.textsObject[routeName]['100'];
  }
}
