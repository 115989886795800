export const textsObject = {
  CreateAccountPage: {
    500: {
      mainTitle: 'Create a free account',
      mainDescription: 'Welcome to CreditPush! To unlock all the powerful tools, please create an account.',
    },
  },
  RestorePasswordPage: {
    100: {
      mainTitle: 'Set a new password',
      mainDescription: 'Please create a new password.',
    },
  },
  BusinessValuePage: {
    100: {
      mainTitle: 'Get a free, instant valuation of your business',
      mainDescription: 'Connect your accountancy or e-commerce apps and get an accurate assessment of your small business with Business Valuation tool. This is a preliminary assessment that’s based on marketing research by Aswath Damodaran.',
    },
    500: {
      mainTitle: 'Get a free, instant valuation of your business',
      mainDescription: 'This is a preliminary assessment based on marketing research of Aswath Damodaran. To get an accurate assessment of your small business worth please connect your accounting app. Your data is safe with us.',
    },
    502: {
      mainTitle: 'Get a free, instant valuation of your business',
      mainDescription: 'This is a preliminary assessment based on marketing research of Ph. McLaud. To get an accurate assessment of your small business worth please connect your accounting app. Your data is safe with us.',
    },
    503: {
      mainTitle: 'Get a free, instant valuation of your business',
      mainDescription: 'This is a preliminary assessment based on marketing research of Ph. McLaud. To get an accurate assessment of your small business worth please connect your accounting app. Your data is safe with us.',
    },
  },
  BusinessValueResultsPage: {
    100: {
      mainTitle: 'Business valuation',
      mainDescription: 'Business valuation provides you with valuable insights and helps in identifying potential areas for improvement by comparing your business to peers on a market.',
    },
    500: {
      mainTitle: 'Business valuation',
      mainDescription: 'Business valuation provides you with valuable insights and helps in identifying potential areas for improvement by comparing your business to peers on a market.',
    },
    502: {
      mainTitle: 'Your business value estimation',
      mainDescription: 'This is a preliminary assessment based on marketing research of Ph. McLaud. To get an accurate assessment of your small business worth please connect your accounting app. Your data is safe with us.',
    },
    503: {
      mainTitle: 'Your business value estimation',
      mainDescription: 'This is a preliminary assessment based on marketing research of Ph. McLaud. To get an accurate assessment of your small business worth please connect your accounting app. Your data is safe with us.',
    },
  },
  RegistrationPage: {
    100: {
      mainTitle: 'Create a free account',
      mainDescription: 'Welcome to CreditPush! To unlock all the powerful tools, please create an account.'
    }
  },
  NamePage: {
    100: {
      mainTitle: 'Tell us about yourself',
      mainDescription: 'We’ll need some basic information to help you get started.',
    },
    103: {
      mainTitle: 'empty text',
      mainDescription: 'empty text',
    },
    104: {
      mainTitle: 'Tell us about yourself',
      mainDescription: 'The information you provide allows us to analyze your business health and suggest loan options tailored to your business needs.',
    },
    200: {
      mainTitle: 'Tell us about yourself',
      mainDescription: 'We’ll need some basic information to help you get started.',
    },
    300: {
      mainTitle: 'Tell us about yourself',
      mainDescription: 'We’ll need some basic information to help you get started.',
    },
    500: {
      mainTitle: 'Tell us about yourself',
      mainDescription: 'We’ll need some basic information to help you get started.',
    },
  },
  AppsPage: {
    100: {
      mainTitle: 'Connect your apps',
      mainDescription: 'Securely connect your business apps to get pre-approved quality financing offers. This data helps credit bureaus and lenders to assess your business and offer loans that fit your current needs.',
    },
    107: {
      mainTitle: 'Connect your business apps',
      mainDescription: 'Securely connect your business apps to get pre-approved quality financing offers. This data helps credit bureaus and lenders to assess your business and offer loans that fit your current needs.',
    },
    206: {
      mainTitle: 'Connect your apps',
      mainDescription: 'Connect as many apps as possible to generate the most accurate and actionable insights to improve your business score.',
    },
    302: {
      mainTitle: 'Connect your business apps',
      mainDescription: 'Connect as many apps as possible to generate the most accurate and actionable insights to improve your business and get funding.',
    },
  },
  BureausPage: {
    107: {
      mainTitle: 'Choose credit bureau(s)',
      mainDescription: 'By clicking next, you agree to share your data with the selected credit bureau(s) to boost your credit score. Sharing your data can only increase your credit score, but NEVER decrease it! Additionally, this may improve your chances of getting better financing.',
    },
    100: {
      mainTitle: 'Choose credit bureau(s)',
      mainDescription: "Please select the credit bureau(s) you'd like to share data with and get free business credit score report.",
    },
    103: {
      mainTitle: 'Choose credit bureau(s)',
      mainDescription: "Please select the credit bureau(s) you'd like to boost your credit score by sharing your business data.",
    },
    104: {
      mainTitle: 'Choose credit bureau(s)',
      mainDescription: 'By clicking next, you agree to share your data with the selected credit bureau(s) to boost your credit score. Sharing your data can only increase your credit score, but NEVER decrease it! Additionally, this may improve your chances of getting better financing.',
    },
    105: {
      mainTitle: 'Choose credit bureau(s)',
      mainDescription: 'By clicking next, you agree to share your data with the selected credit bureau(s) to boost your credit score. Sharing your data can only increase your credit score, but NEVER decrease it! Additionally, this may improve your chances of getting better financing.',
    },
    300: {
      mainTitle: 'Choose credit bureau(s)',
      mainDescription: 'By clicking next, you agree to share your data with the selected credit bureau(s) to boost your credit score. Sharing your data can only increase your credit score, never decrease it!',
    },
    302: {
      mainTitle: 'Choose credit bureau(s)',
      mainDescription: 'By clicking next, you agree to share your data with the selected credit bureau(s) to boost your credit score. Sharing your data can only increase your credit score, never decrease it!',
    },
  },
  CreditScorePage: {
    100: {
      mainTitle: 'Credit score management',
      mainDescription: 'Check, monitor, and boost your business credit score with CreditPush by simply connecting your existing business apps, resulting in improved lending processes and better loan conditions.',
    },
  },
  CashManagementPage: {
    100: {
      mainTitle: 'Bank accounts',
      mainDescription: 'Get a complete view of your income and spending, receive alerts for upcoming payments, and find opportunities to save money. A simple and convenient way to manage your cash in one place.',
    },
  },
  CarbonFootprintPage: {
    100: {
      mainTitle: 'Carbon Footprint',
      mainDescription: 'Carbon Footprint Calculator will make it easy for you to track your footprint and find ways to reduce it. Calculate your monthly footprint, examine the contribution of your purchases and steer toward greener choices.',
    },
  },
  LendioPage: {
    100: {
      mainTitle: 'Ready to get your funding?',
      mainDescription: "Yeap! You got it right. You can get a well deserved loan. Fill out the form and we'll make sure our partners from Lendio reach out to you with the best financing offer.",
    },
    103: {
      mainTitle: 'empty text',
      mainDescription: 'empty text',
    },
    104: {
      mainTitle: 'Apply for funding',
      mainDescription: 'Please check the info before submitting the application. All fields are required.',
    },
  },
  ThankYouPage: {
    100: {
      mainTitle: 'Great job done!',
      mainDescription: 'You are now in good hands with Lendio!',
    },
    103: {
      mainTitle: 'Well done',
      mainDescription: 'While your data is being transferred to selected bureaus you can review your business performance on your CreditPush Dashboard.',
    },
    104: {
      mainTitle: 'Your financial progress starts with CreditPush!',
      mainDescription: 'Here are the 3 ways to help achieve your financial goals.',
    },
    105: {
      mainTitle: 'Your financial progress starts with CreditPush!',
      mainDescription: 'Here are the 3 ways to help achieve your financial goals.',
    },
    200: {
      mainTitle: 'Your financial progress starts with CreditPush!',
      mainDescription: 'Here are the 3 ways to help achieve your financial goals.',
    },
    300: {
      mainTitle: 'Your financial progress starts with CreditPush!',
      mainDescription: 'Here are the 3 ways to help achieve your financial goals.',
    },
    500: {
      mainTitle: 'Your financial progress starts with CreditPush!',
      mainDescription: 'Here are the 2 ways to help achieve your financial goals.',
    },
  },
  GetLoanPage: {
    100: {
      mainTitle: 'Ready to get your funding?',
      mainDescription: "Yeap! You got it right. You can get a well deserved loan. Fill out the form and we'll make sure our partners from Lendio reach out to you with the best financing offer.",
    },
    104: {
      mainTitle: 'Apply for funding',
      mainDescription: 'Please check the info before submitting the application. All fields are required.',
    },
  },
  PortalPage: {
    100: {
      mainTitle: 'Your financial progress starts with CreditPush!',
      mainDescription: 'Here are CreditPush features to help you run your business more smartly!',
    },
  },
  IndustryPage: {
    100: {
      mainTitle: 'Your business industry classification',
      mainDescription: 'Please, select an industry that most correspondent to your business activity to make our algorithm work perfect.',
    },
  },
  AppStatusesPage: {
    100: {
      mainTitle: 'Application statuses',
      mainDescription: 'All of your submitted applications for funding are listed here. Statuses are updated every day.',
    },
  },
};
